<template>
  <div class="container">
    <img :src="oss+'logo.png'" class="logo"/>
    <div class="floats_right">
      <a @click="()=>{
        contentHtml = textInfo.rule
        contentHtmlModal = true
      }"><img :src="oss+'icon_rule.png'"/></a>
      <router-link to="/service"><img :src="oss+'icon_kefu.png'"/></router-link>
      <!--<router-link to="/service" @click="()=>{
            contentHtml = detail.rule
            contentHtmlModal = true
          }"><img :src="oss+'icon_tousu.png'"/></router-link>-->
    </div>

    <div class="start-ani">
      <img :src="style.start" class="start-btn"/>
    </div>

    <div class="tip-c">· {{money.price}}元享开盒子会员享 ·</div>
    <div class="ui_refill">
      <div class="light">
        <img :src="oss+'light1.png'" class="l-part" :style="'display:'+(light==1?'block':'none')"/>
        <img :src="oss+'light2.png'" class="l-part" :style="'display:'+(light==2?'block':'none')"/>
      </div>
      <div class="table">
        <img class="table-con-img" :src="style.content" :style="'transform:rotate('+rotate+'deg);'" width="100%"/>
        <img :src="oss+'move.gif'" class="bling" v-if="blingShow"/>
        <div :class="['table-tag',flag?'rotateani':'']" @click="onPoint"></div>
      </div>

    </div>
    <div class="foot">
      <p>客服电话：4006178158</p>
      <p>{{textInfo.company_name}}</p>
    </div>
    <div class="content" v-html="detail.detail"></div>

    <van-popup v-if="payPopModal" v-model="payPopModal" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" :close-on-click-overlay="false" class="payPop" :style="'background:url('+style.skill_bg+') no-repeat center top;background-size:100% auto;'">
      <div class="fonts">
        <label>￥</label>
        <count-to :start-val="start_val" :end-val="end_val" :duration="1000"></count-to>
        <img :src="oss+'huafei.png'" width="19" height="50"/>
      </div>

      <div class="phone">
        <van-field v-model="json.phone" type="tel" :border="false" ref="phone" placeholder="请输入您的手机号码">
          <template #left-icon>
            <van-icon class-prefix="icon" name="mobile" size="20" color="#444"/>
          </template>
        </van-field>
      </div>
      <div class="paybtn"><van-button round block @click="onsubmit" color="#0ac260">立即充值</van-button></div>
      <div class="tips">
        <span><van-checkbox v-model="isread" icon-size="16px" checked-color="#0ac260">我已阅读并同意</van-checkbox></span>
        <a @click="()=>{
            contentHtml = textInfo.privacy_policy
            contentHtmlModal = true
          }">《用户隐私协议》</a>
        <a @click="()=>{
            contentHtml = textInfo.user_agreement
            contentHtmlModal = true
          }">《用户协议》</a>
      </div>
      <div class="close"><van-icon name="close" @click="()=>{payPopModal = false}" size="36" color="#aaa"/></div>
    </van-popup>

    <van-popup v-if="contentHtmlModal" v-model="contentHtmlModal" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" :close-on-click-overlay="false" class="contentPop">
      <div class="html" v-html="contentHtml"></div>
      <div class="close"><van-button type="primary" round color="#0ac260" style="width:60%" @click="()=>{contentHtmlModal = false}">确定</van-button></div>
    </van-popup>


  </div>
</template>
<script>
  import moment from 'moment'
  import countTo from 'vue-count-to'
  import {isWeixin} from '@/utils/utils'

  import {GetPayConfig,GetUserPrivate} from './services/services'
  export default {
    name:'seckill',
    components: {countTo},
    data () {
      return {
        flag:true,
        noheadimg:this.oss+'header.png',
        start_val:20,
        end_val:100,
        textInfo:{},
        logsList:[],
        optionHover:{
          step:0.4,
          limitMoveNum:2,
          hoverStop:false,
          direction:1,
          openWatch:true,
          singleHeight:20,
          singleWidth:0,
          waitTime:3000,
        },
        money:{},
        json:{
          id:0,
          pay_way:0,
          order_sn:'',
          phone:'',
          account:'',
          url:location.href
        },
        detail:{},
        contentHtmlModal:false,
        contentHtml:'',
        isread:true,
        timer:null,
        payPopModal:false,
        light:1,
        lightTimer:null,
        blingShow:false,
        rotate:0,
        style:{},
        RetentionModal:false,//挽留
      }
    },
    created(){
      const {phone} = this.$route.query

      this.json={
        ...this.json,
        ...this.$route.query,
        phone:(phone==undefined?'':atob(phone))
      }

      this.isWeixin = isWeixin()?1:0
      // this.isWeixin = 1
      if(this.isWeixin){
        this.json.pay_way = 14
      }
      this.type = this.json.type==undefined ? undefined:this.json.type

    },
    mounted(){

      this.getLight()
      this.findpayConfig()
      this.finduserprivate()

    },
    watch:{
      'json.phone':{
        handler:function(value){
          if(this.payPopModal){
            if(value.length == 11){
              if(!/^1[3456789]\d{9}$/.test(value)) {
                this.$toast('请输入正确的手机号码')
                this.$refs.phone.focus()
              }else{
                this.$refs.phone.blur()
              }
            }
          }

        },
        deep:true
      }
    },
    methods:{
      moment,
      getLight(){//灯光
        this.lightTimer = setInterval(() => {
          this.light = this.light == 1?2:1
        },500)
      },
      findpayConfig(){
        GetPayConfig({type:this.type}).then(result => {
          if(result.data.code==1){
            this.detail = result.data.data
            this.style = this.detail.images
            let payWay = this.detail.payConfig[0].payWay
            let pay=this.detail.payConfig.find(item=>item.payWay == payWay)
            const {pwyAccount} = pay
            this.json.pay_way=payWay
            this.json.account=pwyAccount

            let money=this.detail.goodsArray.find(item=>item.status==1)
            this.money=money

            document.title = this.money.price+'元享开盒子会员享'

            this.json.id=money.id
            this.end_val= money.total_price
            const {app_download_url,kefu_url} = this.detail
            let data = {app_download_url,kefu_url}
            sessionStorage.setItem('payConfig',JSON.stringify(data))
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      finduserprivate(){
        GetUserPrivate().then(result => {
          if(result.data.code==1){
            this.textInfo = result.data.data
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      onsubmit(){
        if(!this.isread){
          this.$toast('请阅读隐私协议与用户协议')
          return false
        }
        if(this.json.phone==''){
          this.$toast('请输入充值手机号')
          this.$refs.phone.focus()
          return false
        }else if(!/^1[3456789]\d{9}$/.test(this.json.phone)){
          this.$toast('请输入正确的手机号码')
          this.$refs.phone.focus()
          return false
        }
        let params = {
          ...this.$route.query,
          phone:btoa(this.json.phone)
        }
        this.$router.push({path:'/pay100',query:params})

      },
      onPoint(){
        let time = 4
        if(this.flag){
          this.flag = false
          this.rotate=2180
          setTimeout(()=>{
            this.rotate=2160
            setTimeout(()=>{//
              this.blingShow = true
              setTimeout(()=>{//
                this.payPopModal = true

              },2000)
            },time * 1000)
          },time * 1000)
        }else{
          this.payPopModal = true
        }
      }
    }
  }

</script>
<style scoped lang="less">
  .container{position:relative;padding-top:80px;background:#000 url('@{static-dir}seckill-bg.png') no-repeat left top;background-size:100% auto;
    .floats_right{position:fixed;right:-5px;top:20px;z-index:9;width:52px;
      img{width:100%;}
      a{margin:2px 0px;display:inline-block;}
    }
    .logo{width:30px;position:absolute;left:10px;top:10px;z-index:1;}

    .start-ani{position:fixed;top:0;bottom:0;left:0;right:0;overflow:hidden;background-color:rgba(0,0,0,.8);z-index:15;display:flex;justify-content:center;animation:opacitym-data .5s linear 1s forwards;
      .start-btn{width:200px;height:302px;margin-top:170px;animation:opacityz-data .5s ease-in .5s forwards;}
    }
    .tip-c{text-align:center;background:linear-gradient(to bottom,#f5edd5,#f9e0c7);color:transparent;-webkit-background-clip:text;font-size:14px;font-weight:700;font-family:"Microsoft Yahei";
      span{}
    }
    .ui_refill{width:348px;height:348px;margin:106px auto 0px;position:relative;
      .light{background:url('@{static-dir}content.png') no-repeat center top;background-size:100% auto;
        .l-part{width:100%;display:none;}
      }
      .table{width:303px;height:303px;position:absolute;left:23px;top:23px;z-index:1;display:flex;align-items:center;justify-content:center;
        .table-con-img{position:absolute;z-index:1;transition:transform 4s ease-in-out;}
        .bling{position:absolute;width:300px;height:300px;z-index:16;top:-140px;animation:opacityz-data 1s ease-in forwards;}
        .table-tag{width:120px;height:134px;background:url('@{static-dir}play.png') 50%/100% no-repeat;background-size:100% auto;z-index:2;}
        .rotateani{animation:scalez-data .5s linear infinite alternate;}
      }
    }

    .foot{color:#fff;text-align:center;margin-top:40px;line-height:24px;}
    .content{color:#555;padding:20px;font-size:12px;margin-top:10px;
      /deep/p{padding:4px 0px;}
    }
  }

  .payPop{width:76%;padding:2.1rem 10% 100px 10%;
    .fonts{text-align:center;color:#000;display:flex;justify-content:center;height:100px;line-height:100px;border-bottom:1px solid #ddd;padding-bottom:14px;
      label{padding-top:30px;font-size:36px;margin-right:-4px;}
      span{font-size:120px;}
      img{margin-top:40px;}
    }
    .phone{margin-top:20px;
      .van-cell{border-radius:100px;}
    }
    .paybtn{margin-top:20px;}
    .tips{margin-top:25px;font-size:12px;
      span{display:inline-block;}
      a{color:#0ac260;}
      /deep/.van-checkbox__label{font-size:12px;color:#0ac260;}
    }
    .close{position:absolute;bottom:20px;left:50%;margin-left:-10px;z-index:1;}
  }
  @keyframes scalez-data{
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.1);
    }
  }
  @keyframes opacitym-data{
    0% {
      opacity: 1;
      z-index: 15;
    }
    99% {
      z-index: 15;
    }
    100% {
      opacity: 0;
      z-index: 1;
    }
  }
  @keyframes opacityz-data{
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

</style>
